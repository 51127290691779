@import 'colors';
@import 'shadows';

.App {
  width: 100vw;
  height: 100vh;
}



.CustomerScreen, .AdminSettingsScreen, .BillingScreen, .CustomerContactsScreen, .DataEntryScreen, .ResultsScreen, .AdminAdminScreen {
  padding: 32px;
}

.DataEntryScreen {
  .placeholder {
      font-size: 16px;
      margin: 8px 0;
      color: lighten($dark, 20);
  }
}

.ProcessingCenterCard {

  background-color: #ffffff;
  width: 400px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;
  @include BoxShadow(2);

  transition: background-color 0.3s;

  &:hover {
    background-color: darken($light, 10);
    cursor: pointer;
  }
}

.BillingScreen .error {
    color: $warn;
    margin: 0;
}
