@import 'colors';
@import 'shadows';

.TextInput {
    position: relative;
    margin-bottom: 20px;

    input {
        font-size: 14px;
        padding: 10px;
        display: block;
        width: 230px;
        border: none;
        outline: none;
        background-color: $light;
        @include BoxShadow(1);

        border-bottom: 1px solid transparent;
    }

    input:focus ~ label, input:valid ~ label {
        top: -10px;
        font-size: 12px;
        color: $primary;
    }


    label {
        color: #999;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
    }

    input:focus {
        border-bottom: 1px solid $primary;
    }

    input[type='date'] ~ label, input[type='time'] ~ label {
        font-size: 12px;
        top: -10px;
    }

    input[type='date']:valid ~ label, input[type='time']:valid ~ label {
        color: #999;
    }

    input[type='date']:hover, input[type='time']:hover {
        cursor: pointer;
    }
}

.TextInput2 {
    position: relative;
    margin-bottom: 20px;

    input {
        font-size: 14px;
        padding: 4px;
        display: block;
        width: 100px;
        border: none;
        outline: none;
        background-color: $light;
        @include BoxShadow(1);

        border-bottom: 1px solid transparent;
    }

    input:focus ~ label, input:valid ~ label {
        top: -10px;
        font-size: 12px;
        color: $primary;
    }


    label {
        color: #999;
        font-size: 14px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
    }

    input:focus {
        border-bottom: 1px solid $primary;
    }

    input[type='date'] ~ label, input[type='time'] ~ label {
        font-size: 12px;
        top: -10px;
    }

    input[type='date']:valid ~ label, input[type='time']:valid ~ label {
        color: #999;
    }

    input[type='date']:hover, input[type='time']:hover {
        cursor: pointer;
    }
}
