@import 'colors';
@import 'shadows';

.InvoiceDetail {
    margin-bottom: 32px;
    background-color: $light;
    border-radius: 4px;
    padding: 8px;
    max-width: 900px;

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "detail herds";

    @include BoxShadow(1);


    .Detail {
        grid-area: detail;
        border-right: 2px solid $primary;
        margin-right: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 16px;
        align-items: flex-start;
    }

    .CustomerTitle {
        font-size: 22px;
    }

    .Subtext {
        font-size: 18px;
        color: lighten($dark, 25);
    }

    .LineItems {
        grid-area: herds;
        width: 100%;
    }

    .NoLineItems {
        color: lighten($dark, 50);
    }

    table {
        border-collapse: collapse;

        th {
            text-align: left;
            padding-bottom: 8px;
            font-weight: 500;
            color: $primary;
            border-bottom: 1px solid $primary;
        }
    }

    .LineItem {
        
        width: 100%;
        td {
            padding: 8px 12px;
            border-bottom: 1px solid lighten($dark, 60);
            margin: 0;
            font-size: 14px;
            color: lighten($dark, 30);
        }

        .Description {
            color: lighten($dark, 10);
        }

        &:hover {
            background-color: darken($light, 5);
            @include BoxShadow(1);
        }
    }
}