@import "shadows";
@import "colors";


.button-filled {
    background-color: $primary;
    color: $light;
    padding: 8px 24px;
    display: inline-block;
    font-size: 14px;
    user-select: none;
    white-space: nowrap;
    border: none;
    outline: none;

    border-radius: 4px;
    @include BoxShadow(2);

    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
        background-color: darken($primary, 10);
        @include BoxShadow(4);
        cursor: pointer;
    }

    &:active {
        background-color: lighten($color: $primary, $amount: 5);
    }

    &.warn {
        background-color: $warn;

        &:hover {
            background-color: darken($warn, 10);
        }

        &:active {
            background-color: lighten($warn, 5);
        }
    }
}

.button-text {
    display: inline-block;
    color: $primary;
    user-select: none;
    border: none;
    background: transparent;
    outline: none;
    white-space: nowrap;

    transition: color 0.3s;

    &:hover {
        cursor: pointer;
        color: darken($primary, 10);
    }

    &:active {
        color: lighten($primary, 5);
    }
}

.button-warn {
    display: inline-block;
    color: $warn;
    user-select: none;
    border: none;
    background-color: transparent;

    transition: color 0.3s;
    outline: none;

    &:hover {
        color: darken($warn, 10);
        cursor: pointer;
    }

    &:active {
        color: lighten($warn, 5);
    }
}

.button-none {
    display: none;
}

.button-filled.large, .button-text.large, .button-warn.large {
    font-size: 18px;
}

.button-filled.small, .button-text.small, .button-warn.small {
    font-size: 10px;
    padding: 6px 12px;
    margin-top: 8px;
}
