@import 'colors';
@import 'shadows';

.Tooltip {
    background-color: $light;
    color: darken($light, 50);
    padding: 16px;
    border-radius: 4px;

    @include BoxShadow(6);

    p {
        margin: 5px;
    }

    .date {
        font-weight: bold;
        margin: 0;
    }

    .herd {
        margin: 0;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #milkweight {
        color: $primary;
    }

    #milkweight-comp {
        color: #0db7c6;
    }

    #butterfat {
        color: $yellow;
    }

    #butterfat-comp {
        color: #e28c0b;
    }

    #protein {
        color: $warn;
    }

    #protein-comp {
        color: #d82d13;
    }

    #scc {
        color: $success;
    }

    #scc-comp {
        color: #1c820a;
    }
}

.AnalyticsScreen {
    width: 100%;
    height: 100%;
    padding: 32px;

    display: grid;
    grid-template-rows: 2fr 1fr;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
    "graph percentiles"
    "options percentiles";

    .Graph, .Percentiles, .Options {
        margin: 16px;
        background-color: $light;
        border-radius: 4px;
        @include BoxShadow(2);
    }

    .Graph {
        grid-area: graph;
        padding: 8px;
        font-size: 14px;
        overflow-y: scroll;

        .AnalTable {
            margin: 16px;
            font-family: monospace;

            table {
                border-collapse: collapse;
            }

            td, th {
                padding: 8px 4px;
                margin: 0;
            }

            tr:nth-child(even) {
                background-color: #ccc;
            }
        }
    }

    .leftright {
        display: flex;

        div {
            margin-right: 16px;
        }
    }

    .Percentiles {
        grid-area: percentiles;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-weight: normal;
            width: 100%;
            text-align: center;
            font-size: 24px;
        }

        .rankContainer {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;

            .rank {
                position: relative;
    
                h4 {
                    font-weight: normal;
                    font-size: 18px;
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    color: lighten($dark, 20);
                }
    
                .recharts-surface {
                    position: absolute;
                    left: 0;
                    top: 10px;
                }
    
                p {
                    position: absolute;
                    margin: 0;
                    color: lighten($dark, 40);
                    top: 120px;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }

    .Options {
        grid-area: options;
        padding: 16px;

        h3 {
            margin: 0;
            font-weight: normal;
        }

        .controls {
            display: flex;

            .entities {
                padding-right: 48px !important;
            }

            .entities, .fields {
                padding: 8px;
            }
        }
    }
}
