@import 'colors';
@import 'shadows';


.ContactCard {
    @include BoxShadow(1);
    border-radius: 4px;
    background-color: $light;

    display: flex;
    width: fit-content;
    min-height: 100px;
    min-width: 450px;
    padding: 12px;
    margin-bottom: 16px;

    p {
        margin: 0;
    }

    .Detail {
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .Title {
            font-size: 18px;
            font-weight: 500;
            color: lighten($dark, 10);
        }

        p {
            color: lighten($dark, 20);
            font-size: 12px;
        }

        .Address {
            padding-top: 4px;
            white-space: pre-wrap;
            color: lighten($dark, 40);
        }
    }

    .Preferences {

        .hidden {
            display: none;
        }
    }
}