@import 'colors';
@import 'shadows';

.LandingScreen {
    position: relative;

    .Content {
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        img {
            width: 100%;
            position: absolute;
            bottom: 0;
            z-index: -99;
        }
    
        .Header {
            @include BoxShadow(6);
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            overflow-x: visible;
            white-space: nowrap;
            width: 100vw;
            height: 72px;
            background-color: $dark;
            color: $light;
            padding: 8px;
    
            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                p {
                    margin-left: 8px;
                    display: block;
                }
    
                @media screen and (max-width: 690px) {
                    p {
                        display: none;
                    }
                }
        
                svg {
                    height: 40px;
                    margin-left: 8px;
                }
            }
    
            button {
                margin-right: 32px;
            }
        }
    
        .slogan {
            background-image: url("../assets/cattle.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0% 100%;
            padding: 32px;
            align-self: flex-start;
            width: 100%;
    
            h1, h2 {
                margin: 8px;
                animation: slide-in 0.9s ease-in;
            }
    
            h2 {
                font-size: 2rem;
                color: $light;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 30);
            }
    
            h1 {
                font-size: 3rem;
                color: $dark;
                background-color: $light;
                width: fit-content;
                padding: 8px;
                white-space: nowrap;

                
    
                @include BoxShadow(2);
            }

            @keyframes slide-in {
                0% {
                    opacity: 0;
                }
            }
        }
    
        @media screen and (min-width: 1120px) {
            .slogan {
                padding: 64px;
    
                h1 {
                    font-size: 5rem;
                }
    
                h2 {
                    font-size: 3.5rem;
                }
            }
        }
    
        @media screen and (max-width: 690px) {
            .slogan {
                padding-left: 8px;
                h1 {
                    font-size: 1.5rem;
                }
                h2 {
                    font-size: 1.0rem;
                }
            }
        }
    
        .section {
            max-width: 800px;
            width: 50%;
            text-align: left;
            font-size: 1.2em;
            color: lighten($dark, 20);
        }
    }

    .Footer {
        margin-top: 16px;
        background-color: $dark;
        color: $light;
        width: 100%;
        padding: 8px;

        p {
            margin: 0;
            font-size: 0.7rem;
            text-align: center;
        }

        a {
            color: $primary;
        }
    }
}
