@import 'colors';
@import 'shadows';

.Textarea {
    resize: none;
    width: 100%;
    border: none;
    outline: none;

    @include BoxShadow(1);

    transition: box-shadow, border-bottom;
    transition-duration: 0.3s;

    &:focus {
        border-bottom: 1px solid $primary;
    }

    &:hover {
        @include BoxShadow(2);
    }
}