@import 'colors';
@import 'shadows';

.ResultDetail {
    width: fit-content;
    padding: 4px;
    margin-bottom: 50px;

    @include BoxShadow(1);
    background: $light;

    display: flex;

    div {
        margin: 4px;
    }

    .details {
        line-height: 1;
        p {
            margin: 4px;
        }
        margin-right: 16px;
        margin-bottom: 8px;

        .title {
            font-size: 24px;
        }

        .date {
            font-size: 16px;
            color: $primary;
        }
    }

    .deltas {
        line-height: 1;
        margin: 0;
        padding: 0;
        div {
            display: flex;
            justify-content: space-between;
            p {
                margin: 0;
                padding-right: 32px;
            }

            p:nth-child(even) {
                color: lighten($dark, 50);
            }

            p:nth-child(odd) {
                color: lighten($dark, 30);
            }

            p.red {
                color: $warn;
            }

            p.green {
                color: $success;
            }
        }
    }
    
}