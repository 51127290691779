@import 'colors';
@import 'shadows';

.CheckContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .Checkbox {

        border: 1px solid lighten($dark, 70);
        width: 15px;
        height: 15px;
        background-color: $light;
    
        display: flex;
        align-items: center;
        justify-self: center;
    
        border-radius: 4px;
        @include BoxShadow(1);
        margin-right: 8px;
    
        &:hover {
            cursor: pointer;
        }
    
       input {
           display: none;
       }
    
       svg {
           width: 15px;
           height: 15px;
           margin: 0;
           padding: 0;
           fill: $primary;
       }
    
       svg.hidden {
           opacity: 0;
       }

       &:hover {
           @include BoxShadow(2);
       }
    }

    .label {
        font-size: 12px;
        color: lighten($dark, 25);
        user-select: none;
    }
}

