@import 'colors';
@import 'shadows';

.AdminAdminScreen {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 5fr 2fr;
    grid-template-areas:
        "admin-actions admin-table"
        "admin-graph admin-table";

    height: 100%;

    #admin-actions, #admin-graph, #admin-table {
        background-color: white;
        padding: 16px;
        margin: 16px;
        border-radius: 8px;
        @include BoxShadow(1);
    }

    #admin-actions {
        grid-area: admin-actions;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        * {
            margin: 6px auto;
        }
    }

    #admin-graph {
        grid-area: admin-graph;
    }

    #admin-table {
        grid-area: admin-table;
        overflow-y: scroll;

        table {
            margin: 16px;
            font-family: monospace;
            border-collapse: collapse;

            table {
                border-collapse: collapse;
            }

            td, th {
                padding: 8px 16px;
                margin: 0;
                text-align: right;
            }

            tr:nth-child(even) {
                background-color: #ccc;
            }
        }
    }
}
