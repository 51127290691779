@import 'colors';
@import 'shadows';


.FilesUpload {

    margin: 8px 0px;

    .horizontal {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }    

    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    label {
        font-size: 16px;
        color: $primary;
        cursor: pointer;
        background-color: $light;
 
        padding: 8px;
        border-radius: 4px;
        transition: all ease-in 0.3s;

        @include BoxShadow(1);

        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;

        svg {
            fill: $primary;
            padding-right: 8px;
            height: 24px;
        }
    }

    label:hover {
        background-color: darken($light, 5);
        @include BoxShadow(2);
    }

    .table {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 16px;
        background-color: $light;

        @include BoxShadow(1);

        p.header {
            background-color: $dark;
            color: $light;
        }

        p {
            margin: 0;
            padding: 8px;
        }

        .rpt {
            p.header {
                border-radius: 4px 0 0 0;
            }

        }

        .out {
            p.header {
                border-radius: 0 4px 0 0;
            }
        }

        .listitem {
            background-color: $light;
            margin: 4px;
            border-radius: 4px;

            @include BoxShadow(1);

            &:hover {
                cursor: pointer;
                background-color: darken($light, 5);
            }

            &.selected {
                background-color: red;
            }
        }
    }
}