@import 'colors';
@import 'shadows';

.SelectInput {
    color: lighten($dark, 25);
    position: relative;
    margin-bottom: 20px;

    width: min-content;
    min-width: 200px;
    background-color: $light;

    #selected {
        cursor: pointer;
        border: none;
        outline: none;
        margin-bottom: 8px;
        padding: 8px;
        min-height: 16px;

        p {
            white-space: nowrap;
            margin-right: 32px;
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        transition: border-bottom, box-shadow;
        transition-duration: 0.3s;
        user-select: none;

        border-bottom: 2px solid transparent;

        border-radius: 4px;
        @include BoxShadow(1);

        &:hover {
            @include BoxShadow(2);
        }

        &:focus {
            border-bottom: 2px solid $primary;
        }

        svg {
            position: absolute;
            top: 10px;
            right: 5px;
            fill: lighten($dark, 25);
            z-index: 2;

            transition: transform 0.3s;
        }

        svg.rotated {
            transform: rotate(180deg);
        }
        
    }

    #selected:focus ~ label {
        color: $primary;
    }

    #selectedvalue {
        display: none;
    }

    #options {
        background-color: $light;
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 50px;
        border-radius: 4px;
        z-index: 99;
        @include BoxShadow(2);

        max-height: 200px;
        overflow-y: scroll;

        div {
            padding: 8px;
            user-select: none;
        }

        div:hover {
            cursor: pointer;
            background-color: lighten($dark, 80);
        }

        &.hidden {
            display: none;
        }
    }

    select:focus ~ label {
        color: $primary;
    }

    label {
        color: #999;
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: -15px;
        white-space: nowrap;
    }
}
