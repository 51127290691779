@import 'colors';
@import 'shadows';

.EditableList {
    width: 250px;
    margin-bottom: 20px;
    border-radius: 4px;

    @include BoxShadow(1);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $dark;
        color: $light;
        padding: 8px;

        svg {
            fill: $primary;
            @include BoxShadow(1);

            &:hover {
                cursor: pointer;
                fill: darken($primary, 10);
                @include BoxShadow(2);
            }
        }

        border-radius: 4px 4px 0 0;
    }

    .items {
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            background-color: $light;

            &:hover {
                background-color: darken($light, 10);
                cursor: pointer;
            }

            svg {
                fill: $warn;

                &:hover {
                    fill: darken($warn, 10);
                    cursor: pointer;
                }
            }
        }
    }
}