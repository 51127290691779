@import 'colors';
@import 'shadows';

.nav-container {
    display: grid;
    grid-template-columns: 192px auto;
    grid-template-rows: 72px auto;

    position: fixed;

    width: 100%;
    height: 100vh;

    grid-template-areas: 
    "logo header"
    "bar content";

    .nav-logo {
        grid-area: logo;
        background-color: $dark;
        z-index: 0;
        color: $light;

        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 24px;
        overflow-x: visible;
        white-space: nowrap;

        img, p {
            margin-left: 8px;
            display: block;
        }

        img {
            height: 40px;
        }
    }

    .nav-header {
        grid-area: header;
        background-color: $dark;
        @include BoxShadow(6);
        display: flex;
        justify-content: flex-end;
        padding-right: 32px;
        align-items: center;
        color: $light;
    }
    
    .nav-bar {
        grid-area: bar;
        background-color: #393d42;
        @include BoxShadow(6);

        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 8px;

        a {
            font-size: 18px;
            padding: 12px 0px;
            width: 100%;
            text-align: center;
            text-decoration: none;

            background-color:  #393d42;
            color: darken($light, 10);
            user-select: none;

            transition: background-color 0.3s, box-shadow 0.3s;
            
            &:hover {
                cursor: pointer;
                background-color: lighten($dark, 5);
            }

            &.selected {
                // background-color: lighten($dark, 10);
                background-color: $primary;
                color: $light;
            }
        }
    }
    
    .nav-content {
        grid-area: content;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;
    }
}

