@import 'colors';
@import 'shadows';

.LoginScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .LoginCard {
        border-radius: 4px;
        @include BoxShadow(2);
        padding: 32px;

        position: relative;
        top: 20vh;
        background-color: $light;

        p {
            font-size: 24px;
        }

        .Error {
            font-size: 14px;
            color: $warn;
        }

        .Buttons {
            padding-top: 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .LoginHeader {
        @include BoxShadow(6);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 24px;
        overflow-x: visible;
        white-space: nowrap;
        width: 100vw;
        height: 72px;
        background-color: $dark;
        color: $light;
        p {
            margin-left: 8px;
            display: block;
        }

        svg {
            height: 40px;
            margin-left: 8px;
        }
    }
}
