@import 'colors';
@import 'shadows';

.CustomerDetail {
    margin-bottom: 32px;
    background-color: $light;
    border-radius: 4px;
    padding: 8px;
    min-width: 600px;
    width: min-content;

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    "detail herds";

    @include BoxShadow(1);

    .Name {
        font-size: 18px;
        white-space: nowrap;
    }

    .Email {
        font-size: 14px;
        color: lighten($dark, 40);
    }

    .Detail {
        grid-area: detail;
        border-right: 2px solid $primary;
        margin-right: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 16px;
        align-items: flex-start;
    }

    .Obsolete {
        color: $warn;
    }

    .CustomerTitle {
        font-size: 24px;
        white-space: nowrap;
    }

    .Subtext {
        font-size: 14px;
        color: lighten($dark, 25);
    }

    .RILS {
        border-top: 2px solid $primary;
        margin-top: 16px;

        p {
            margin: 0;
            margin-bottom: 8px;
            font-size: 14px;
        }

        .ril {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;

            @include BoxShadow(1);

            &:hover{
                cursor: pointer;
                background-color: darken($light, 10);
                @include BoxShadow(2);
            }

            p {
                font-size: 12px;
                padding: 8px;
                margin: 0;
            }
        }
    }

    .RightSide {
        grid-area: herds;
        display: flex;
        flex-direction: column;
    }

    .NoHerds {
        color: lighten($dark, 50);
    }

    .HerdCard {
        @include BoxShadow(1);
        border-radius: 4px;
        padding: 8px;

        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr auto;
        grid-template-areas:
        "title detail"
        "comments comments";

        .Type {
            font-size: 14px;
            color: lighten($dark, 40);
        }

        .Title {
            grid-area: title;
            margin-right: 16px;
        }

        .HerdCardDetail {
            display: flex;
            flex-direction: column;
            color: lighten($dark, 25);
            font-size: 14px;

            grid-area: detail;

            div {
                display: flex;
                margin: 0;
                padding: 0;
                justify-content: space-between;
                line-height: 1.1;

                p {
                    margin: 0;
                    margin-right: 48px;
                    white-space: nowrap;
                }

                p:last-child {
                    color: lighten($dark, 50);
                }
            }
        }

        .Comments {
            font-size: 14px;
            padding-top: 16px;
            grid-area: comments;
            color: lighten($dark, 25);
            padding: 8px;
        }

    }
}