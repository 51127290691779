@import 'colors';
@import 'shadows';

.modal-scrim.hidden {
    opacity: 0;
    background-color: #ffffff;
    z-index: -99;
}

.modal-scrim {
    position: fixed;
    left: 0;
    top: 0;
    background-color: transparentize($dark, 0.4);
    width: 100vw;
    height: 100vh;
    // backdrop-filter: blur(5px);

    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 0.2s, background-color 0.2s;
    // transition: z-index 0.3s 0.3s;

    .modal-card {
        background-color: $alt-light;
        min-width: 500px;
        padding: 8px;

        position: relative;

        max-height: 90vh;
        overflow-y: scroll;

        display: grid;
        grid-template-columns: 5fr auto;
        grid-template-rows: auto auto auto;
        grid-template-areas: 
        "title top-action"
        "items ."
        ". bottom-action";

        @include BoxShadow(2);
        border-radius: 4px;

        .modal-title {
            grid-area: title;
            font-size: 24px;
            text-align: left;
            padding-left: 8px;
            padding-bottom: 16px;
        }

        .modal-items {
            margin-top: 8px;
            grid-area: items;
            padding-left: 8px;
            width: min-content;

            .text {
                color: lighten($dark, 20);
                width: 100%;
            }

            .Error {
                font-size: 12px;
                color: $warn;
            }

            .double {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: start;

                * {
                    margin: 4px;
                }
            }

            .triple {
                display: grid;
                grid-template-columns: auto auto auto;

                * {
                    margin: 4px;
                }
            }

            .FilesUpload {
                align-self: flex-start;
            }

            .verifyTable {
                td, th {
                    white-space: nowrap;
                    padding: 0 4px;
                }
            }

            .diff.neg {
                color: $warn;
            }

            .diff.pos {
                color: $success;
            }
        }

        .modal-top-action {
            grid-area: top-action;
            justify-self: end;
            padding: 8px;
        
        }

        .modal-bottom-action {
            grid-area: bottom-action;
            justify-self: center;
            width: 100%;

            display: flex;
            justify-content: space-around;
            align-items: center;

            * {
                margin: 8px;
            }
        }
    }
}

