@import 'colors';
@import 'shadows';

.CustomerReportScreen {
    margin: 16px;
    

    .container {
        display: flex;
        flex-direction: column;
        width: min-content;

        li {
            color: $primary;
            transition: opacity 0.3s;
        }
    
        .hidden {
            opacity: 0;
        }

        .animation {
            display: flex;
        }
    }
}